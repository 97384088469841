<template>
  <div
    class="full-width-slider"
    ref="container"
    @touchstart="handleTouchStart"
    @touchmove="handleTouchMove"
    @touchend="handleTouchEnd"
  >
    <div
      class="slider-container"
      ref="slider"
      :style="{
        transform: `translateX(${-containerWidth * currentIndex +
          touchDeltaX}px)`
      }"
    >
      <div
        v-for="(item, index) in sliders"
        :key="index"
        class="slider-item"
        :ref="'slide' + index"
      >
        <div class="i">
          <picture>
            <source
              :srcset="require('@/assets' + item.srcset)"
              media="(max-width: 767px)"
            />
            <img :src="require('@/assets' + item.src)" alt="" />
          </picture>
        </div>
        <div class="t">
          <p v-html="item[`text_${$i18n.locale}`]"></p>
        </div>
      </div>
    </div>
    <div class="slider-offset"><div></div></div>
    <!-- <div class="controls">
      <span class="prev-button" @click.prevent="prevSlide">
        <svg
          width="55"
          height="55"
          viewBox="0 0 55 55"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="27.5"
            cy="27.5"
            r="27.5"
            fill="black"
            fill-opacity="0.4"
          />
          <path
            d="M17.9375 26.9375L29.9375 15C30.5 14.375 31.4375 14.375 32.0625 15C32.625 15.5625 32.625 16.5 32.0625 17.0625L21.0625 28L32 39C32.625 39.5625 32.625 40.5 32 41.0625C31.4375 41.6875 30.5 41.6875 29.9375 41.0625L17.9375 29.0625C17.3125 28.5 17.3125 27.5625 17.9375 26.9375Z"
            fill="white"
          />
        </svg>
      </span>
      <span class="next-button" @click.prevent="nextSlide">
        <svg
          width="55"
          height="55"
          viewBox="0 0 55 55"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="27.5"
            cy="27.5"
            r="27.5"
            fill="black"
            fill-opacity="0.4"
          />
          <path
            d="M36.0625 26.9375C36.625 27.5625 36.625 28.5 36.0625 29.0625L24.0625 41.0625C23.4375 41.6875 22.5 41.6875 21.9375 41.0625C21.3125 40.5 21.3125 39.5625 21.9375 39L32.875 28.0625L21.9375 17.0625C21.3125 16.5 21.3125 15.5625 21.9375 15C22.5 14.375 23.4375 14.375 24 15L36.0625 26.9375Z"
            fill="white"
          />
        </svg>
      </span>
    </div> -->
    <!-- <div class="pagination">
      <span
        v-for="(slide, index) in sliders"
        :key="index"
        :class="{ active: index === currentIndex }"
        @click="goToSlide(index)"
      ></span>
    </div> -->
    <ul class="slider-nav">
      <li class="prev"><a href="#" @click.prevent="prevSlide"></a></li>
      <li v-for="(item, idx) in sliders" :key="item.id">
        <a
          href="#"
          :class="{ active: currentIndex === idx }"
          @click.prevent="goToSlide(idx)"
        ></a>
      </li>
      <li class="next"><a href="#" @click.prevent="nextSlide"></a></li>
    </ul>
  </div>
</template>

<script>
import cover from "@/mixins/cover";

export default {
  props: {},
  mixins: [cover],
  data() {
    return {
      containerWidth: 0,
      startX: 0,
      startY: 0,
      touchDeltaX: 0,
      currentIndex: 0,
      // screenWidth: 0,
      sliders: [
        {
          srcset: "/img/img-14-s.jpg",
          src: "/img/img-14.jpg",
          text_en:
            "Boeing 777-300 aircraft decorated with the image of the rarest Amur leopard",
          text_ru:
            "Boeing 777-300 с изображением самого редкого дальневосточного леопарда"
        },
        {
          srcset: "/img/img-15-s.jpg",
          src: "/img/img-15.jpg",
          text_en: "Airbus A319 in a livery dedicated to Russian sports",
          text_ru: "Airbus A319 в ливрее, посвященной российскому спорту"
        },
        {
          srcset: "/img/img-19-s.jpg",
          src: "/img/img-19.jpg",
          text_en: "Superjet 100 Troika livery",
          text_ru: "Superjet 100 в ливрее «Тройка»"
        },
        {
          srcset: "/img/img-20-s.jpg",
          src: "/img/img-20.jpg",
          text_en: "Superjet 100 Hermitage livery",
          text_ru: "Superjet 100 в ливрее «Эрмитаж»"
        },
        {
          srcset: "/img/img-16-s.jpg",
          src: "/img/img-16.jpg",
          text_en:
            "Superjet 100 in a livery dedicated to the 300th anniversary of SPbU",
          text_ru: "Superjet 100 в ливрее, посвящённой 300-летию СПбГУ"
        },
        // {
        //   srcset: "/img/img-17-s.jpg",
        //   src: "/img/img-17.jpg",
        //   text_en: "Boeing 747 (EI-XLD) aircraft in a special tiger livery",
        //   text_ru: "Boeing 747 (EI-XLD) в специальной тигриной ливрее"
        // },
        {
          srcset: "/img/img-18-s.jpg",
          src: "/img/img-18.jpg",
          text_en:
            "We carry medicines across the country for Konstantin Khabensky Charitable Foundation",
          text_ru:
            "Перевозим лекарственные препараты по стране для подопечных благотворительного фонда Константина Хабенского"
        }
      ]
    };
  },
  mounted() {
    window.addEventListener("resize", this.getSliderWidth);
    this.getSliderWidth();
    this.startAutoSlide(); // Запускаем автоперелистывание при монтировании компонента
  },
  beforeDestroy() {
    this.stopAutoSlide(); // Останавливаем автоперелистывание перед уничтожением компонента
  },
  destroyed() {
    window.removeEventListener("resize", this.getSliderWidth);
  },
  methods: {
    getSliderWidth() {
      this.containerWidth =
        this.$refs.slide0[0].offsetWidth +
        parseInt(getComputedStyle(this.$refs.slide0[0]).marginLeft) +
        parseInt(getComputedStyle(this.$refs.slide0[0]).marginRight);
    },
    // getScreenWidth() {
    //   this.screenWidth = window.innerWidth;
    // },
    // Добавляем метод для запуска автоперелистывания
    startAutoSlide() {
      this.autoSlideInterval = setInterval(() => {
        this.nextSlide(); // Вызываем метод для перехода к следующему слайду
      }, 5000); // Интервал автоперелистывания в миллисекундах (10 секунд)
    },
    // Добавляем метод для остановки автоперелистывания
    stopAutoSlide() {
      if (this.autoSlideInterval) {
        clearInterval(this.autoSlideInterval);
      }
    },
    handleTouchStart(event) {
      this.stopAutoSlide(); // Останавливаем автоперелистывание при начале взаимодействия с пользователем
      this.$refs.slider.style.transition = "none";
      this.startX = event.touches[0].clientX;
      this.startY = event.touches[0].clientY;
    },
    handleTouchMove(event) {
      const deltaX = event.touches[0].clientX - this.startX;
      const deltaY = event.touches[0].clientY - this.startY;

      if (Math.abs(deltaX) > Math.abs(deltaY)) {
        event.preventDefault(); // Предотвращаем прокрутку страницы при свайпе
        this.touchDeltaX = deltaX;
      }
    },
    handleTouchEnd(event) {
      const deltaX = event.changedTouches[0].clientX - this.startX;
      //   const deltaY = event.changedTouches[0].clientY - this.startY;

      //   if (Math.abs(deltaX) > Math.abs(deltaY)) {
      // Свайп горизонтальный
      if (Math.abs(deltaX) > this.containerWidth * 0.3) {
        // Прокрутили достаточно далеко, меняем слайд
        if (deltaX > 0) {
          this.switchSlideWithAnimation(
            (this.currentIndex - 1 + this.sliders.length) % this.sliders.length
          );
        } else {
          this.switchSlideWithAnimation(
            (this.currentIndex + 1) % this.sliders.length
          );
        }
      }
      //   }
      this.touchDeltaX = 0;
      this.startAutoSlide(); // Запускаем автоперелистывание после завершения взаимодействия с пользователем
    },
    // Метод для переключения слайдов с анимацией
    switchSlideWithAnimation(index) {
      this.currentIndex = index;
      this.$refs.slider.style.transition = "transform 1s ease";
      this.$refs.slider.style.transform = `translateX(-${this.containerWidth *
        this.currentIndex}px)`;
    },
    // Метод для переключения слайдов без анимации
    switchSlideChangeTimeAnimation(index, transitionTime) {
      this.currentIndex = index;
      this.$refs.slider.style.transition = transitionTime;
      this.$refs.slider.style.transform = `translateX(-${this.containerWidth *
        this.currentIndex}px)`;
    },
    nextSlide() {
      this.stopAutoSlide(); // Останавливаем автоперелистывание при начале взаимодействия с пользователем
      if (this.currentIndex + 2 > this.sliders.length) {
        this.switchSlideChangeTimeAnimation(
          (this.currentIndex + 1) % this.sliders.length,
          "transform 1s ease"
        );
      } else {
        this.switchSlideWithAnimation(
          (this.currentIndex + 1) % this.sliders.length
        );
      }
      this.startAutoSlide(); // Запускаем автоперелистывание после завершения взаимодействия с пользователем
    },
    prevSlide() {
      this.stopAutoSlide(); // Останавливаем автоперелистывание при начале взаимодействия с пользователем
      if (this.currentIndex - 1 < 0) {
        this.switchSlideChangeTimeAnimation(
          (this.currentIndex - 1 + this.sliders.length) % this.sliders.length,
          "transform 1s ease"
        );
      } else {
        this.switchSlideWithAnimation(
          (this.currentIndex - 1 + this.sliders.length) % this.sliders.length
        );
      }
      this.startAutoSlide(); // Запускаем автоперелистывание после завершения взаимодействия с пользователем
    },
    goToSlide(index) {
      this.stopAutoSlide(); // Останавливаем автоперелистывание при начале взаимодействия с пользователем
      this.switchSlideWithAnimation(index);
      this.startAutoSlide(); // Запускаем автоперелистывание после завершения взаимодействия с пользователем
    }
  }
};
</script>

<style scoped>
.full-width-slider {
  overflow: hidden;
}

.controls {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.pagination {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
}

.pagination span {
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: gray;
  border-radius: 50%;
  margin: 0 5px;
  cursor: pointer;
}

.pagination span.active {
  background-color: black;
}
</style>
