<template>
  <main role="main">
    <meta-title :title="metaTitle"></meta-title>
    <section class="section section-hero-image">
      <picture>
        <source
          srcset="@/assets/i/banners/plane/plane-414.jpg"
          media="(max-width: 374px)"
        />
        <source
          srcset="@/assets/i/banners/plane/plane-414.jpg"
          media="(max-width: 413px)"
        />
        <source
          srcset="@/assets/i/banners/plane/plane-768.jpg"
          media="(max-width: 767px)"
        />
        <source
          srcset="@/assets/i/banners/plane/plane-768.jpg"
          media="(max-width: 1023px)"
        />
        <source
          srcset="@/assets/i/banners/plane/plane-1024.jpg"
          media="(max-width: 1279px)"
        />
        <source
          srcset="@/assets/i/banners/plane/plane-1280.jpg"
          media="(max-width: 1439px)"
        />
        <source
          srcset="@/assets/i/banners/plane/plane-1440.jpg"
          media="(max-width: 1599px)"
        />
        <img
          src="@/assets/i/banners/plane/plane-1440.jpg"
          width="100%"
          alt=""
        />
      </picture>
    </section>

    <section class="section section-default section-text">
      <div class="container m-container-only-s">
        <h1 class="page-header" v-html="$t('airline_new.header')"></h1>

        <div class="hero">
          <p v-html="$t('airline_new.hero')"></p>
          <p v-html="$t('airline_new.hero_2')"></p>
          <p v-html="$t('airline_new.hero_3')"></p>
          <p v-html="$t('airline_new.hero_4')"></p>
          <p v-html="$t('airline_new.hero_5')"></p>
          <p v-html="$t('airline_new.hero_6')"></p>
          <p v-html="$t('airline_new.hero_7')"></p>
          <p v-html="$t('airline_new.hero_8')"></p>
          <p v-html="$t('airline_new.hero_9')"></p>
          <p v-html="$t('airline_new.hero_10')"></p>
          <p v-html="$t('airline_new.hero_11')"></p>
          <p v-html="$t('airline_new.hero_12')"></p>
          <p v-html="$t('airline_new.hero_13')"></p>
          <p v-html="$t('airline_new.hero_14')"></p>
        </div>

        <profile-slider-one></profile-slider-one>
        <!-- <profile-slider-two></profile-slider-two> -->
        <InfoSliderTwo/>
        <!-- <profile-slider-three></profile-slider-three> -->
      </div>
    </section>
  </main>
</template>

<script>
import { mapActions, mapState } from "vuex";
import cover from "@/mixins/cover";
import InfoSliderTwo from "../../components/profile/InfoSliderTwo.vue";
// import ProfileSliderTwo from "@/components/profile/ProfileSliderTwo";
import ProfileSliderOne from "@/components/profile/ProfileSliderOne";

export default {
  name: "Airline",
  components: { ProfileSliderOne,InfoSliderTwo },
  title() {
    return this.$t("pages.flight");
  },
  mixins: [cover],
  data: () => {
    return {
      video: null,
      step: 0,
      carouselPositions: [],
      halfContainer: 0,
      interval: false,
      headerItems: [
        {
          sources: [
            {
              srcset: "/img/Aircraft/Sportolet-XS.jpg",
              media: "(max-width:374px)"
            },
            {
              srcset: "/img/Aircraft/Sportolet-XS.jpg",
              media: "(max-width: 413px)"
            },
            {
              srcset: "/img/Aircraft/Sportolet-XS.jpg",
              media: "(max-width: 767px)"
            },
            {
              srcset: "/img/Aircraft/Sportolet-S.jpg",
              media: "(max-width: 1023px)"
            },
            {
              srcset: "/img/Aircraft/Sportolet-M.jpg",
              media: "(max-width:1279px)"
            },
            {
              srcset: "/img/Aircraft/Sportolet-XL.jpg",
              media: "(max-width: 1439px)"
            },
            {
              srcset: "/img/Aircraft/Sportolet-XL.jpg",
              media: "(max-width:1599px)"
            }
          ],

          image: "/img/Aircraft/Sportolet-XL.jpg",
          title: "airline.sportolet_title",
          text: "airline.sportolet"
        },
        {
          sources: [
            {
              srcset: "/img/Aircraft/Tigrolet-XS.jpg",
              media: "(max-width:374px)"
            },
            {
              srcset: "/img/Aircraft/Tigrolet-XS.jpg",
              media: "(max-width: 413px)"
            },
            {
              srcset: "/img/Aircraft/Tigrolet-XS.jpg",
              media: "(max-width: 767px)"
            },
            {
              srcset: "/img/Aircraft/Tigrolet-S.jpg",
              media: "(max-width: 1023px)"
            },
            {
              srcset: "/img/Aircraft/Tigrolet-M.jpg",
              media: "(max-width:1279px)"
            },
            {
              srcset: "/img/Aircraft/Tigrolet-XL.jpg",
              media: "(max-width: 1439px)"
            },
            {
              srcset: "/img/Aircraft/Tigrolet-XL.jpg",
              media: "(max-width:1599px)"
            }
          ],
          image: "/img/Aircraft/Tigrolet-XL.jpg",
          title: "airline.tigrolet_title",
          text: "airline.tigrolet"
        },
        {
          sources: [
            {
              srcset: "/img/Aircraft/Zenitolet-XS.jpg",
              media: "(max-width:374px)"
            },
            {
              srcset: "/img/Aircraft/Zenitolet-XS.jpg",
              media: "(max-width: 413px)"
            },
            {
              srcset: "/img/Aircraft/Zenitolet-XS.jpg",
              media: "(max-width: 767px)"
            },
            {
              srcset: "/img/Aircraft/Zenitolet-S.jpg",
              media: "(max-width: 1023px)"
            },
            {
              srcset: "/img/Aircraft/Zenitolet-M.jpg",
              media: "(max-width:1279px)"
            },
            {
              srcset: "/img/Aircraft/Zenitolet-XL.jpg",
              media: "(max-width: 1439px)"
            },
            {
              srcset: "/img/Aircraft/Zenitolet-XL.jpg",
              media: "(max-width:1599px)"
            }
          ],
          image: "/img/Aircraft/Zenitolet-XL.jpg",
          title: "airline.zenitolet_title",
          text: "airline.zenitolet"
        },
        {
          sources: [
            {
              srcset: "/img/Aircraft/Leolet-XS.jpg",
              media: "(max-width:374px)"
            },
            {
              srcset: "/img/Aircraft/Leolet-XS.jpg",
              media: "(max-width: 413px)"
            },
            {
              srcset: "/img/Aircraft/Leolet-XS.jpg",
              media: "(max-width: 767px)"
            },
            {
              srcset: "/img/Aircraft/Leolet-S.jpg",
              media: "(max-width: 1023px)"
            },
            {
              srcset: "/img/Aircraft/Leolet-M.jpg",
              media: "(max-width:1279px)"
            },
            {
              srcset: "/img/Aircraft/Leolet-XL.jpg",
              media: "(max-width: 1439px)"
            },
            {
              srcset: "/img/Aircraft/Leolet-XL.jpg",
              media: "(max-width:1599px)"
            }
          ],
          image: "/img/Aircraft/Leolet-XL.jpg",
          title: "airline.leolet_title",
          text: "airline.leolet"
        }
      ]
    };
  },
  computed: {
    ...mapState("home", {
      indexData: state => state.index,
      indexLoading: state => state.indexLoading
    }),
    ...mapState("settings", {
      enabledDrm: state => state.enabledDrm
    }),
    ...mapState({
      locale: state => state.locale
    }),
    ...mapState("section", {
      indexSectionData: state => state.index,
      indexSectionLoading: state => state.indexLoading
    }),
    backgroundHeaderImage() {
      return {
        backgroundImage: `url(${require("@/assets/" +
          this.headerItems[this.step].image)})`
      };
    }
  },
  mounted() {},
  methods: {
    ...mapActions("home", {
      actionIndex: "index"
    }),
    ...mapActions({
      setLocale: "SET_LOCALE"
    })
  }
};
</script>

<style scoped></style>
